@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&family=Open+Sans&display=swap");
@import url("https://s3.amazonaws.com/icomoon.io/152489/FIGIcons/style.css?9fqie0");
@tailwind base;
/*Extend base elements after this point*/
html {
  font-size: 62.5%;
}

body {
  @apply font-body text-gray-900 text-base leading-normal;
}

@tailwind components;
/*Write custom components after this point*/
.active {
  @apply text-primary-500 font-semibold;
}

#mobile-nav.ng-enter {
  animation: fadeInOut 200ms ease normal;
}

#mobile-nav.ng-leave {
  animation: fadeInOut 200ms ease reverse;
}

#loading-container.ng-leave {
  animation: fadeInOut 200ms ease reverse 1200ms;
}

input:not([disabled]).ng-invalid.ng-touched, input:not([readonly]).ng-invalid.ng-touched, textarea:not([disabled]).ng-invalid.ng-touched, textarea:not([readonly]).ng-invalid.ng-touched {
  @apply border-red-300;
}

.btn {
  @apply rounded-full bg-transparent border-2 h-48 px-20 transition duration-150 uppercase font-display font-semibold text-xs tracking-wide;
}

.btn[disabled] {
  @apply bg-gray-400 text-white border-gray-400 cursor-default;
}

.btn:not([disabled]).btn--primary {
  @apply text-accent-500 border-accent-500;
}

.btn:not([disabled]).btn--primary:hover {
  @apply bg-accent-500 text-white;
}

.btn:not([disabled]).btn--primary:focus {
  @apply outline-none shadow-outline;
}

@tailwind utilities;
/*Write custom utils after this point*/
@keyframes fadeInOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.bg-hero {
  @apply bg-cover bg-fixed bg-bottom bg-no-repeat;
  background-image: url("../Images/bg-hero.jpg");
}

.bg-section {
  @apply bg-center bg-cover bg-no-repeat;
  background-image: url("../Images/pattern-section.svg");
}

.fig-24px {
  font-size: 24px;
}

.fig-36px {
  font-size: 36px;
}

.fig-48px {
  font-size: 48px;
}

.leading-custom-1 {
  line-height: 0.9;
}